<div *ngIf="item">
	<div class="row">
		<div class="col">
			<item-publish-button
				[item]="item"
				channel="Web"
				name="Hemsida"
				republish="true"
				(change)="publishChanged($event)"
			></item-publish-button>
		</div>
		<div class="col">
			<item-publish-button
				[item]="item"
				channel="Lokalguiden"
				name="Lokalguiden"
				(change)="publishChanged($event)"
			></item-publish-button>
		</div>
		<div class="col">
			<item-publish-button
				[item]="item"
				channel="Lokalnytt"
				name="Lokalnytt"
				(change)="publishChanged($event)"
			></item-publish-button>
		</div>
		<div class="col">
			<item-publish-button
				[item]="item"
				channel="Objektvision"
				name="Objektvision"
				republish="true"
				(change)="publishChanged($event)"
			></item-publish-button>
		</div>
	</div>
</div>
